import { sendDataLayerOnLinkClickEvent } from "jiffy-analytics";

export const attachGAEvent = link => {
  link.addEventListener("click", e => {
    const { gaEventAction, gaEventLabel } = link.dataset;
    if (gaEventAction && gaEventLabel) {
      sendDataLayerOnLinkClickEvent(e, {
        genericEvent: true,
        eventCategory: "Facets",
        event: gaEventAction,
        eventLabel: gaEventLabel,
        eventValue: 1
      });
      if (gaEventLabel === "Jiffy 1st") {
        sendDataLayerOnLinkClickEvent(e, {
          genericEvent: true,
          eventCategory: "PLP Filters",
          event: "Filter Click",
          eventLabel: "Jiffy First Filter",
          eventValue: 1
        });
      }
    }
  });
};

// https://gomakethings.com/how-to-test-if-an-element-is-in-the-viewport-with-vanilla-javascript/
const isInViewport = button => {
  const rect = button.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const clickOnTitles = classes => {
  const titles = document.querySelectorAll(classes.titleClass);
  const collapseAll = () => {
    const links = document.querySelectorAll(classes.linkClass);
    titles.forEach(title => {
      title.setAttribute("data-collapsed", "true");
      title.classList.add(classes.collapsedTitleClassName);
      title.classList.remove(classes.collapseTitleClassName);
    });
    links.forEach(link => {
      link.classList.add(classes.hiddenLinkClassName);
    });
  };

  titles.forEach(title => {
    const { categoryIdx, accordion } = title.dataset;
    const links = document.querySelectorAll(
      `${classes.linkClass}[data-category-idx="${categoryIdx}"]`
    );
    title.addEventListener("click", () => {
      let { collapsed } = title.dataset;

      if (accordion === "true") {
        collapseAll();

        setTimeout(() => {
          if (!isInViewport(title)) {
            title.scrollIntoView();
          }
        }, 100); // Wait for ending animation
      }
      collapsed = collapsed !== "true";
      title.setAttribute("data-collapsed", collapsed);
      title.setAttribute("aria-expanded", !collapsed);
      title.classList.toggle(classes.collapsedTitleClassName, collapsed);
      title.classList.toggle(classes.collapseTitleClassName, !collapsed);

      links.forEach(link => {
        link.classList.toggle(classes.hiddenLinkClassName, collapsed);
      });
    });
  });
};

export const clickOnToggles = classes => {
  const $toggles = document.querySelectorAll(classes.toggleClass);

  $toggles.forEach($toggle => {
    const { categoryIdx, maxCollapsed } = $toggle.dataset;
    const links = document.querySelectorAll(
      `${classes.linkClass}[data-category-idx="${categoryIdx}"]`
    );

    $toggle.addEventListener("click", () => {
      let { collapsed } = $toggle.dataset;

      collapsed = collapsed !== "true";
      $toggle.setAttribute("data-collapsed", collapsed);
      $toggle.classList.toggle(classes.collapseToggleClassName, !collapsed);
      $toggle.blur();

      links.forEach((link, idx) => {
        link.classList.toggle(
          classes.hiddenLinkClassName,
          collapsed && parseInt(maxCollapsed, 10) <= idx
        );
      });
    });

    // accessibility
    $toggle.addEventListener("keydown", () => {
      const { collapsed } = $toggle.dataset;
      const isCollapsed = collapsed === "true";
      const $fourthItem = $toggle.parentElement.children[4];
      if (!$fourthItem) return;

      $fourthItem.ontransitionend = () => {
        if (isCollapsed) {
          $fourthItem.focus();
        } else {
          $toggle.focus();
        }
      };
    });
  });
};

export default {
  clickOnTitles,
  clickOnToggles,
  attachGAEvent
};
