import { attachGAEvent, clickOnTitles, clickOnToggles } from "./aside-filter-events";
import "./aside-filter.scss";

const titleStates = {
  titleClass: ".js-collapse-filters-title",
  linkClass: ".js-collapse-filters-link",
  collapsedTitleClassName: "aside-filter__title--collapsed",
  collapseTitleClassName: "aside-filter__title--collapse",
  hiddenLinkClassName: "aside-filter__item--hidden"
};

const togglesStates = {
  toggleClass: ".js-aside-filter-toggle",
  linkClass: ".js-collapse-filters-link",
  collapseToggleClassName: "aside-filter__toggle--collapse",
  hiddenLinkClassName: "aside-filter__item--hidden"
};

const initProductsCollapsableFiltersForm = () => {
  const $checkbox = document.querySelector(".js-aside-filter-checkbox");

  $checkbox?.addEventListener("change", () => {
    const $form = document.querySelector(".js-aside-filter-form");
    window.location.href = $form.action;
  });
};

export default () => {
  const container = document.querySelector(".js-aside-filter");
  const links = document.querySelectorAll(".js-collapse-filters-ga-link");

  if (!container) return;

  clickOnTitles(titleStates);
  clickOnToggles(togglesStates);
  initProductsCollapsableFiltersForm();
  links.forEach(attachGAEvent);
};
